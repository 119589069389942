var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col items-center w-full h-24 border-l-4 border-transparent cursor-pointer",
      class: {
        "bg-blue-100 border-blue-500 ": _vm.isActive,
        "hover:border-gray-400 hover:bg-blue-50": !_vm.isActive,
      },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "w-full h-full text-center",
          attrs: {
            "data-cy": "sideNavLinkButtonComponent",
            to: { name: _vm.route },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-4" },
            [
              _c("icon-component", {
                staticClass: "mx-auto cursor-pointer",
                attrs: {
                  icon: _vm.icon,
                  width: 24,
                  height: 24,
                  fill: _vm.isActive ? _vm.BLUE : _vm.GRAY,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "pt-3 overflow-x-auto text-sm text-gray-400 capitalize",
              class: { "text-blue-700 ": _vm.isActive },
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }