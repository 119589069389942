
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CheckboxComponent from '@/components/Forms/Elements/CheckboxComponent.vue';
import { ELogActionType } from '@/enums/EActionTypes';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { User } from '@/models/Entities/User';
import { formatDate } from '@/services/formatService';
import { CheckBoxOptions } from '@/models/Forms/FormOption';
import { EUserRoles } from '@/enums';

@Component<ApplicantLogsComponent>({
  components: {
    IconComponent,
    ButtonComponent,
    CheckboxComponent,
    Dropdown
  },
  filters: {
    formatDate(date: Date) {
      return formatDate(date);
    }
  }
})
export default class ApplicantLogsComponent extends Vue {
  @Prop()
  applicant!: Applicant;

  //This is a temp fix for an issue that will not be present when notes workflow changes
  @VModel()
  isImmediateAction!: boolean;

  @Prop()
  logs!: Log[];

  actionTypes = Object.values(ELogActionType);
  logText = '';
  actionType: ELogActionType | null = null;

  get currentUser(): User {
    return this.$store.getters['userModule/currentUser'];
  }

  get actionIsRequired(): boolean {
    return this.isImmediateAction;
  }

  get immediateActionLogs(): Log[] {
    return this.logs.filter((log) => log.actionRequired);
  }

  @Emit('close')
  closeNotes(): void {
    return;
  }

  canArchiveLog(log: Log): boolean {
    return (
      log.senderUid === this.currentUser.uid ||
      this.currentUser.role === EUserRoles.ADMIN
    );
  }

  actionRequiredMessage(log: Log): 'Action Required' | 'Action Completed' {
    return log.completed ? 'Action Completed' : 'Action Required';
  }

  actionTypeExists(log: Log): boolean {
    return log.actionRequired && !log.completed;
  }

  @Emit('update')
  markCompleted(log: Log): Log {
    log.actionRequired = false;
    log.completed = true;
    log.completedBy = this.currentUser.displayName;
    log.completedDateTime = new Date();
    return log;
  }

  @Emit('archive')
  archiveLog(log: Log): Log {
    return log;
  }

  get checkboxItems(): CheckBoxOptions[] {
    return [{ label: 'Requires Follow Up, Immediate Action', value: true }];
  }

  get currentUserUid(): string | null {
    return this.currentUser.uid;
  }

  get valid(): boolean {
    return this.logText.trim() !== '';
  }

  async createLog(): Promise<void> {
    const log = new Log({
      logCreatorDisplayName: this.currentUser.displayName,
      text: this.logText,
      senderUid: this.currentUserUid,
      subject: '',
      actionRequired: this.isImmediateAction,
      completed: false,
      completedDateTime: null,
      completedBy: '',
      actionType: this.actionType
    });
    this.$emit('create', log);
    this.isImmediateAction = false;
    this.logText = '';
    this.actionType = null;
  }
}
