
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { IconDatum, IconTable } from '@/assets/icons';
import Routes from '@/router/Routes';

@Component<SideNavLinkButtonComponent>({ components: { IconComponent } })
export default class SideNavLinkButtonComponent extends Vue {
  @Prop({ default: 'title' })
  text!: string;

  @Prop({ default: () => IconTable.pencil })
  icon!: IconDatum;

  @Prop({ required: true })
  route!: Routes;

  get isActive(): boolean {
    return this.$route.name ? this.$route.name.includes(this.route) : false;
  }

  //colors for the icons and text
  readonly BLUE = '#3e66fb';
  readonly GRAY = '#c2c2c9';
}
