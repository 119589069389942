var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "p-4 px-8 text-xs" }, [
          _c("h2", { staticClass: "mb-4 text-xl" }, [
            _vm._v(" Letters in Leiu of Orders Traffic Management Office "),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("date-selector", {
                  attrs: {
                    "data-cy": "DateDeparting",
                    label: "Date Departing",
                    initialDate: _vm.initialDate,
                    required: true,
                    placeholder: "Select a date",
                    errorText: "Please select a departure date",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("dateOfDeparture", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.dateDeparting,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "dateDeparting", $$v)
                    },
                    expression: "tmoData.dateDeparting",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex flex-col mb-6 md:flex-row" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/3" },
              [
                _c("number-input", {
                  attrs: {
                    "data-cy": "NumberOfTravelDays",
                    errorText: "Enter number of travel days",
                    regex: _vm.Regex.number,
                    required: true,
                    maxlength: 4,
                    placeholder: "0",
                    label: "Number of travel days",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("NumberOfTravelDays", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.numberOfTravelDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "numberOfTravelDays", $$v)
                    },
                    expression: "tmoData.numberOfTravelDays",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-0 mt-6 md:w-1/3 md:ml-8 md:my-0" },
              [
                _c("number-input", {
                  attrs: {
                    "data-cy": "NumberOfDependents",
                    errorText: "Enter number of dependents",
                    regex: _vm.Regex.number,
                    required: true,
                    maxlength: 3,
                    placeholder: "0",
                    label: "Number of dependents",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("NumberOfDependents", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.numberOfDependents,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "numberOfDependents", $$v)
                    },
                    expression: "tmoData.numberOfDependents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-0 mt-6 md:w-1/3 md:ml-8 md:my-0" },
              [
                _c("number-input", {
                  attrs: {
                    "data-cy": "NumberOfVehicles",
                    errorText: "Enter number of vehicles",
                    regex: _vm.Regex.number,
                    required: true,
                    maxlength: 3,
                    placeholder: "0",
                    label: "Number of vehicles",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("NumberOfVehicles", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.numberOfVehicles,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "numberOfVehicles", $$v)
                    },
                    expression: "tmoData.numberOfVehicles",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "FundCityFy",
                    errorText: "Enter fund city FY",
                    required: true,
                    placeholder: "Enter Location",
                    label: "Fund City FY",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("FundCityFy", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.fundCite,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "fundCite", $$v)
                    },
                    expression: "tmoData.fundCite",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "TravelFromLocation",
                    errorText: "Enter location being traveled from",
                    required: true,
                    placeholder: "Enter Location",
                    label: "Travel From Location",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("TravelFromLocation", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.fromLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "fromLocation", $$v)
                    },
                    expression: "tmoData.fromLocation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "TravelToLocation",
                    errorText: "Enter location being traveled to",
                    required: true,
                    placeholder: "Enter Location",
                    label: "Travel To Location",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("TravelToLocation", $event)
                    },
                  },
                  model: {
                    value: _vm.tmoData.toLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmoData, "toLocation", $$v)
                    },
                    expression: "tmoData.toLocation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "flex items-center justify-end" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                  attrs: { "data-cy": "TmoModalCancelButton", type: "button" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 text-white rounded focus:outline-none focus:shadow-outline",
                  class: {
                    "opacity-50": !_vm.valid,
                    "bg-gray-500": !_vm.valid,
                    "bg-blue-500": _vm.valid,
                  },
                  attrs: {
                    "data-cy": "TmoModalSubmitButton",
                    type: "button",
                    disabled: !_vm.valid,
                  },
                  on: { click: _vm.generateLetterHandler },
                },
                [_vm._v(" Generate Letter ")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }