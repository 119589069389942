
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Routes from '@/router/Routes';

@Component<EmployerLetterModal>({
  components: {
    DateSelector,
    Modal
  }
})
export default class EmployerLetterModal extends Vue {
  @Prop()
  readonly candidateName!: string;

  formState: IFormState = {};
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;
  isLoading = false;
  initialDate = new Date(new Date().setFullYear(new Date().getFullYear()));

  startDate: Date | null = null;

  close(): void {
    this.show = false;
    this.startDate = null;
    this.resolve();
  }

  generateLetterHandler(): void {
    this.show = false;
    this.$router.push({
      name: Routes.LETTER_EMPLOYEE,
      params: {
        startDate: this.startDate as any,
        candidateName: this.candidateName
      }
    });
    this.resolve();
  }

  public open(): Promise<any> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  cancel(): void {
    this.startDate = null;
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1 &&
      !this.isLoading
    );
  }
}
