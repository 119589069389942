var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.applicant
    ? _c("div", { staticClass: "flex flex-col h-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center border-r" },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-center flex-grow-0 flex-shrink-0 p-4 w-28",
              },
              [
                _c("profile-photo-component", {
                  attrs: {
                    "data-cy": "profilePhoto",
                    photoLocation: _vm.photoLocation,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "flex-col ml-2 text" }, [
              _c("h4", { staticClass: "text-xs" }, [
                _vm._v(_vm._s(_vm.applicant.fullName)),
              ]),
              _c("h6", { staticClass: "text-xs" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.applicant.identificationInformationEdipi
                        ? `ID: ${_vm.applicant.identificationInformationEdipi}`
                        : ""
                    ) +
                    " "
                ),
              ]),
              _c("h6", { staticClass: "text-xs text-gray-500" }, [
                _vm._v(
                  " Updated: " +
                    _vm._s(
                      _vm._f("formatDate")(_vm.applicant.lastChangedDateTime)
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "action-menu-component",
              {
                staticClass: "ml-auto mr-4 text-sm",
                attrs: {
                  "data-cy": "actionMenu",
                  dropDownTextColor: "text-blue-500",
                  menuWidth: "80",
                  showNumberSelected: false,
                  alignment: "right",
                  items: _vm.actionMenuItems,
                },
                on: { itemClick: _vm.itemClickHandler },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "p-2 px-4 mt-1 ml-auto text-white rounded bg-val-button-blue",
                    attrs: { "data-cy": "profileActionsMenu" },
                  },
                  [
                    _c("icon-component", {
                      staticClass: "fill-current",
                      attrs: { name: "lightningBolt", height: 18, width: 18 },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "flex h-full" }, [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col flex-shrink-0 h-full border-t border-r w-28",
            },
            _vm._l(_vm.sideMenuNavigationItems, function (item, index) {
              return _c("side-nav-link-button-component", {
                key: index,
                attrs: {
                  "data-cy": `linkButtonComponent-${index}`,
                  icon: item.icon,
                  text: item.text,
                  route: item.route,
                },
              })
            }),
            1
          ),
          _c(
            "section",
            { staticClass: "w-full h-full border-t border-r" },
            _vm._l(_vm.sectionStatus, function (item, index) {
              return _c("section-status-component", {
                key: index,
                attrs: { header: item.header, entries: item.entries },
              })
            }),
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }