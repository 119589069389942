var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex w-full" },
    [
      _c(
        "div",
        { staticClass: "flex-grow-0 flex-shrink-0 w-400" },
        [
          _c("applicant-side-nav-component", {
            attrs: {
              applicant: _vm.applicant,
              applications: _vm.applications,
              role: _vm.currentUser.role,
            },
            on: {
              toggleNote: function ($event) {
                _vm.showNotes = !_vm.showNotes
              },
              toggleOutProcessing: function ($event) {
                _vm.showOutProcessing = !_vm.showOutProcessing
              },
              modal: _vm.openModal,
            },
          }),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "flex w-full max-w-screen-xl overflow-hidden border-r" },
        [_c("router-view", { on: { updateApplicant: _vm.updateApplicant } })],
        1
      ),
      _c(
        "slide-out-component",
        {
          attrs: { open: _vm.showNotes },
          on: {
            close: function ($event) {
              _vm.showNotes = false
            },
          },
        },
        [
          _c("applicant-logs-component", {
            attrs: {
              applicant: _vm.applicant,
              currentUser: _vm.currentUser,
              logs: _vm.applicantLogs,
            },
            on: {
              close: _vm.closeNoteSlideout,
              create: _vm.createLog,
              update: _vm.updateLog,
              archive: _vm.archiveLog,
            },
            model: {
              value: _vm.immediateAction,
              callback: function ($$v) {
                _vm.immediateAction = $$v
              },
              expression: "immediateAction",
            },
          }),
        ],
        1
      ),
      _c("tmo-letter-modal", {
        ref: "tmoLetterModal",
        attrs: { candidateName: _vm.applicant.fullName },
      }),
      _c("housing-letter-modal", {
        ref: "housingLetterModal",
        attrs: { candidateName: _vm.applicant.fullName },
      }),
      _c("employer-letter-modal", {
        ref: "employerLetterModal",
        attrs: { candidateName: _vm.applicant.fullName },
      }),
      _c("yes-no-modal-component", { ref: "yesNoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }