
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { SectionStatusType } from '../types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

type IconProps = {
  name: string;
  fill?: string;
};

const USABLE_ICONS = {
  complete: { name: 'checkCircleFilled', fill: '#3E66FB' },
  untouched: { name: 'circleFilledMinus', fill: 'gray' },
  incomplete: { name: 'circleFilledMinus', fill: '#ff0000' },
  partial: { name: 'circleHalfFilled' }
};

@Component<SectionStatusComponent>({
  components: { IconComponent, FontAwesomeIcon }
})
export default class SectionStatusComponent extends Vue {
  @Prop({ default: 'title' })
  header!: string;

  @Prop()
  entries!: SectionStatusType[];

  open = true;

  hoverIndex = null;

  getDoneStatusIcon(item: SectionStatusType): IconProps {
    switch (item.status) {
      case ESectionStatus.UNTOUCHED: {
        return USABLE_ICONS.untouched;
      }
      case ESectionStatus.PARTIAL: {
        return USABLE_ICONS.partial;
      }
      case ESectionStatus.COMPLETE: {
        return USABLE_ICONS.complete;
      }
      case ESectionStatus.INCOMPLETE: {
        return USABLE_ICONS.incomplete;
      }
    }
  }

  sectionClicked(item: SectionStatusType): void {
    if (item.onClick) {
      item.onClick();
    }
  }
}
