
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProfilePhotoComponent from '@/components/Applicant/ProfilePhotoComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { ELetterInLeuiTypes, EUserRoles } from '@/enums';
import { formatDateTime } from '@/services/formatService';
import ActionMenuComponent from '@/components/ActionMenus/ActionMenuComponent.vue';
import SideNavLinkButtonComponent from '@/components/Applicant/SideNavLinkButtonComponent.vue';
import SectionStatusComponent from './SectionStatusComponent.vue';
import { IconDatum, IconTable } from '@/assets/icons';
import Routes from '@/router/Routes';
import { ActionMenuItem } from '@/models/ActionMenuItem';
import { iconColors } from '@/assets/iconColors';
import { SectionStatusData } from '../types';

type SectionLink = {
  text: string;
  route: Routes;
  icon: IconDatum;
};

enum ACTIONS {
  CALL,
  TEXT,
  EMAIL,
  CREATE_TMO_LETTER,
  CREATE_HOUSING_LETTER,
  CREATE_EMPLOYER_LETTER,
  CREATE_NOTE,
  CREATE_IMMEDIATE_ACTION_NOTE
}

@Component<ApplicantSideNavComponentV2>({
  components: {
    ProfilePhotoComponent,
    IconComponent,
    ActionMenuComponent,
    SideNavLinkButtonComponent,
    SectionStatusComponent
  },
  filters: {
    formatDate(date: Date) {
      return formatDateTime(date);
    }
  }
})
export default class ApplicantSideNavComponentV2 extends Vue {
  @Prop()
  readonly applicant!: Applicant;

  @Prop()
  readonly role!: EUserRoles;

  $window!: {
    location: any;
  };

  get sectionStatus(): SectionStatusData[] {
    const sections =
      this.$store.getters['profileSideNavigationModule/sectionStatusState'];
    return sections.filter(
      (section: SectionStatusData) => section.entries?.length
    );
  }

  get actionMenuItems(): ActionMenuItem[] {
    return [
      new ActionMenuItem({
        displayText: `Call ${this.applicant.formattedHomePhone}`,
        action: () => {
          this.$window.location.assign(
            `tel:${this.applicant.formattedHomePhone}`
          );
        },
        icon: {
          name: 'phone',
          fill: iconColors.lightBlue
        },
        value: ACTIONS.CALL,
        showCount: false
      }),
      new ActionMenuItem({
        displayText: `Text ${this.applicant.formattedHomePhone}`,
        action: () => {
          this.$window.location.assign(
            `sms:${this.applicant.formattedHomePhone}`
          );
        },
        icon: { name: 'message' },
        value: ACTIONS.TEXT,
        showCount: false
      }),
      new ActionMenuItem({
        displayText: `Email ${this.applicant.contactInformationEmail ?? ''}`,
        action: () => {
          this.$window.location.assign(
            `mailto:${this.applicant.contactInformationEmail}`
          );
        },
        icon: { name: 'email' },
        value: ACTIONS.EMAIL,
        showCount: false
      }),
      new ActionMenuItem({
        displayText: `Create TMO letter`,
        action: () => {
          this.$emit('modal', ELetterInLeuiTypes.TMO);
        },
        icon: {
          name: 'document',
          fill: iconColors.lightBlue
        },
        value: ACTIONS.CREATE_TMO_LETTER,
        showCount: false,
        hasSeparator: true
      }),
      new ActionMenuItem({
        displayText: `Create Housing letter`,
        action: () => {
          this.$emit('modal', ELetterInLeuiTypes.HOUSING);
        },
        icon: {
          name: 'document',
          fill: iconColors.lightBlue
        },
        value: ACTIONS.CREATE_HOUSING_LETTER,
        showCount: false
      }),
      new ActionMenuItem({
        displayText: `Create Employer letter`,
        action: () => {
          this.$emit('modal', ELetterInLeuiTypes.EMPLOYER);
        },
        icon: {
          name: 'document',
          fill: iconColors.lightBlue
        },
        value: ACTIONS.CREATE_EMPLOYER_LETTER,
        showCount: false
      }),
      new ActionMenuItem({
        displayText: `Add a note`,
        action: () => {
          this.$emit('toggleNote');
        },
        icon: { name: 'pencil' },
        value: ACTIONS.CREATE_NOTE,
        showCount: false,
        hasSeparator: true
      })
    ];
  }
  get sideMenuNavigationItems(): SectionLink[] {
    return [
      {
        text: 'profile',
        icon: IconTable.circleFilledPlus,
        route: Routes.V2_APPLICANT_PROFILE
      },
      {
        text: 'Applications',
        icon: IconTable.documentText,
        route: Routes.V2_APPLICANT_APPLICATION
      },
      {
        text: 'Vitals',
        icon: IconTable.heartbeat,
        route: Routes.V2_APPLICANT_VITALS
      },
      {
        text: 'Gains',
        icon: IconTable.documentPlus,
        route: Routes.V2_APPLICANT_GAINS
      },
      {
        text: 'InPro',
        icon: IconTable.circleArrowWithCheck,
        route: Routes.V2_APPLICANT_IN_PROCESSING
      },
      {
        text: 'OTS',
        icon: IconTable.medal,
        route: Routes.V2_APPLICANT_OFFICER_TRAINING_SCHOOL
      },
      {
        text: 'Documents',
        icon: IconTable.documentsInFolder,
        route: Routes.V2_APPLICANT_DOCUMENTS
      },
      {
        text: 'Notes',
        icon: IconTable.stickyNote,
        route: Routes.V2_APPLICANT_NOTES_AND_ORDERS
      },
      {
        text: 'Out Processing',
        icon: IconTable.chevronRightMulti,
        route: Routes.V2_APPLICANT_PROCESSING
      }
    ];
  }
  get photoLocation(): string | null {
    return this.applicant.documentsProfilepicturelocation;
  }

  itemClickHandler(item: ActionMenuItem): void {
    item.action();
  }
}
