
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import NumberInput from '@/components/Forms/Elements/NumberInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Regex from '@/models/Forms/Regex';
import Routes from '@/router/Routes';

@Component<TmoLetterModal>({
  components: {
    DateSelector,
    Modal,
    NumberInput,
    TextInput
  }
})
export default class TmoLetterModal extends Vue {
  @Prop()
  readonly candidateName!: string;

  formState: IFormState = {};
  Regex = Regex;
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;
  isLoading = false;
  initialDate = new Date(new Date().setFullYear(new Date().getFullYear()));

  tmoData = {
    dateDeparting: null,
    numberOfTravelDays: null,
    numberOfDependents: null,
    numberOfVehicles: null,
    fundCite: null,
    fromLocation: null,
    toLocation: null
  };

  close(): void {
    this.show = false;
    this.tmoData = {
      dateDeparting: null,
      numberOfTravelDays: null,
      numberOfDependents: null,
      numberOfVehicles: null,
      fundCite: null,
      fromLocation: null,
      toLocation: null
    };
    this.resolve();
  }

  generateLetterHandler() {
    this.show = false;
    this.$router.push({
      name: Routes.LETTER_TRAFFIC,
      params: {
        dateDeparting: this.tmoData.dateDeparting as any,
        numberOfTravelDays: this.tmoData.numberOfTravelDays as any,
        numberOfDependents: this.tmoData.numberOfDependents as any,
        numberOfVehicles: this.tmoData.numberOfVehicles as any,
        fundCite: this.tmoData.fundCite as any,
        fromLocation: this.tmoData.fromLocation as any,
        toLocation: this.tmoData.toLocation as any,
        candidateName: this.candidateName
      }
    });
    this.resolve();
  }

  public open(): Promise<any> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  cancel(): void {
    this.tmoData = {
      dateDeparting: null,
      numberOfTravelDays: null,
      numberOfDependents: null,
      numberOfVehicles: null,
      fundCite: null,
      fromLocation: null,
      toLocation: null
    };
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1 &&
      !this.isLoading
    );
  }
}
