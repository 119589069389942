var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col w-full h-full bg-white flex-nowrap" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "w-full pb-4 border-b" },
        [
          _c("div", { staticClass: "w-full p-4 mb-4" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.logText,
                  expression: "logText",
                },
              ],
              staticClass:
                "w-full p-4 py-2 text-sm bg-white border border-gray-400 rounded-lg appearance-none focus:outline-none focus:shadow-outline",
              attrs: { maxlength: 500 },
              domProps: { value: _vm.logText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.logText = $event.target.value
                },
              },
            }),
            _c(
              "span",
              { staticClass: "float-right mr-4 text-xs text-label-gray" },
              [_vm._v("500 characters")]
            ),
          ]),
          _c("checkbox-component", {
            staticClass: "mx-4",
            attrs: { checkboxItems: _vm.checkboxItems },
            model: {
              value: _vm.isImmediateAction,
              callback: function ($$v) {
                _vm.isImmediateAction = $$v
              },
              expression: "isImmediateAction",
            },
          }),
          _c("dropdown", {
            staticClass: "mx-4 my-2",
            attrs: {
              placeholder: "Select Action Type",
              label: "Action Type",
              values: _vm.actionTypes,
            },
            model: {
              value: _vm.actionType,
              callback: function ($$v) {
                _vm.actionType = $$v
              },
              expression: "actionType",
            },
          }),
          _c(
            "button-component",
            {
              staticClass: "mx-4 my-2",
              class: { "opacity-50": !_vm.valid },
              attrs: {
                textOrBorderColor: "white",
                bg: "val-button-blue",
                type: "button",
                disabled: !_vm.valid,
              },
              on: { click: _vm.createLog },
            },
            [
              _vm._v(
                " Add " +
                  _vm._s(_vm.isImmediateAction ? "Immediate Action" : "Note") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full overflow-y-scroll" },
        _vm._l(_vm.immediateActionLogs, function (log, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "flex-shrink-0 pt-5 mx-4 mt-4 mb-1 border-t border:last first:border-0",
            },
            [
              log.actionRequired
                ? _c(
                    "div",
                    {
                      staticClass: "ml-1 text-sm font-bold",
                      class: log.completed ? "text-green-600" : "text-red-600",
                    },
                    [_vm._v(" " + _vm._s(_vm.actionRequiredMessage(log)) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center flex-shrink-0 w-auto h-8 bg-gray-200 rounded-full",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c("icon-component", {
                          staticClass: "flex-shrink-0 stroke-current stroke-0",
                          attrs: { name: "user", width: 18, height: 18 },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ml-2 mr-2" }, [
                      _vm._v(" " + _vm._s(log.logCreatorDisplayName) + " "),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "ml-2 overflow-y-auto text-xs text-gray-500" },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("formatDate")(log.createDateTime)) + " "
                  ),
                ]
              ),
              _c("div", { staticClass: "my-2 ml-2 overflow-y-auto text-sm" }, [
                _vm._v(" " + _vm._s(log.text) + " "),
              ]),
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _vm.actionTypeExists(log)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center flex-shrink-0 my-2 overflow-y-auto text-sm",
                        },
                        [
                          log.actionType
                            ? _c("icon-component", {
                                staticClass:
                                  "flex-shrink-0 ml-2 stroke-current stroke-0",
                                attrs: { name: "flag", width: 14, height: 14 },
                              })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(log.actionType) + " "),
                          _c(
                            "button-component",
                            {
                              staticClass: "ml-2 mr-4",
                              attrs: {
                                textOrBorderColor: "val-button-blue",
                                bg: "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.markCompleted(log)
                                },
                              },
                            },
                            [_vm._v("Mark Completed")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canArchiveLog(log)
                    ? _c(
                        "button-component",
                        {
                          staticClass: "ml-2 mr-4",
                          attrs: { textOrBorderColor: "red-500", bg: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.archiveLog(log)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "flex flex-shrink-0 p-4 mt-auto border-t" },
        [
          _c(
            "button-component",
            {
              staticClass: "mr-4",
              attrs: { textOrBorderColor: "white", bg: "val-button-blue" },
              on: { click: _vm.closeNotes },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "text-2xl font-semibold" }, [
        _vm._v("Notes / Immediate Actions"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }