
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Routes from '@/router/Routes';

@Component<HousingLetterModal>({
  components: {
    DateSelector,
    Modal,
    TextInput
  }
})
export default class HousingLetterModal extends Vue {
  @Prop()
  readonly candidateName!: string;

  formState: IFormState = {};
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;
  isLoading = false;
  initialDate = new Date(new Date().setFullYear(new Date().getFullYear()));

  housingData = {
    dateArriving: null,
    fromLocation: null,
    toLocation: null
  };

  close(): void {
    this.show = false;
    this.housingData = {
      dateArriving: null,
      fromLocation: null,
      toLocation: null
    };
    this.resolve();
  }

  generateLetterHandler(): void {
    this.show = false;
    this.$router.push({
      name: Routes.LETTER_HOUSING,
      params: {
        dateDeparting: this.housingData.dateArriving as any,
        fromLocation: this.housingData.fromLocation as any,
        toLocation: this.housingData.toLocation as any,
        candidateName: this.candidateName
      }
    });
    this.resolve();
  }

  public open(): Promise<any> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  cancel(): void {
    this.housingData = {
      dateArriving: null,
      fromLocation: null,
      toLocation: null
    };
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1 &&
      !this.isLoading
    );
  }
}
