var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass: "mt-2 ml-4",
      attrs: { "data-cy": "sectionStatusComponent" },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex items-center mb-2 cursor-pointer",
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("icon-component", {
            staticClass: "mr-2 duration-300 transform",
            class: _vm.open ? "rotate-180" : "rotate-0",
            attrs: {
              name: "chevronDownThin",
              width: 14,
              height: 14,
              fill: "black",
            },
          }),
          _c("p", { staticClass: "text-xl font-bold capitalize" }, [
            _vm._v(" " + _vm._s(_vm.header) + " "),
          ]),
        ],
        1
      ),
      _vm.open
        ? _c(
            "div",
            _vm._l(_vm.entries, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex items-center mb-3 ml-4 cursor-pointer hover:text-blue-500",
                  attrs: { "data-cy": `section-${index}` },
                },
                [
                  item.status !== null
                    ? _c("icon-component", {
                        staticClass: "mr-2",
                        attrs: {
                          name: _vm.getDoneStatusIcon(item).name,
                          width: 14,
                          height: 14,
                          fill: _vm.getDoneStatusIcon(item).fill,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "capitalize",
                      on: {
                        click: function ($event) {
                          return _vm.sectionClicked(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.text))]
                  ),
                  item.extraAction
                    ? _c(
                        "div",
                        { staticClass: "h-6 ml-auto mr-4" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "h-4 overflow-visible",
                            style: {
                              color:
                                _vm.hoverIndex !== index
                                  ? item.extraAction.iconColor
                                  : "blue",
                            },
                            attrs: { icon: item.extraAction.icon },
                            on: {
                              click: item.extraAction.onClick,
                              mouseover: function ($event) {
                                _vm.hoverIndex = index
                              },
                              mouseleave: function ($event) {
                                _vm.hoverIndex = null
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }