
import {
  Component,
  Mixins,
  Prop,
  ProvideReactive,
  Vue,
  Watch
} from 'vue-property-decorator';
import ApplicantSideNavComponentV2 from '@/components/Applicant/ApplicantSideNavComponentV2.vue';
import ApplicantLogsComponent from '@/components/Applicant/ApplicantLogsComponent.vue';
import { ELetterInLeuiTypes } from '@/enums';
import TmoLetterModal from '@/components/Modals/LetterInLeui/TmoLetterModal.vue';
import HousingLetterModal from '@/components/Modals/LetterInLeui/HousingLetterModal.vue';
import EmployerLetterModal from '@/components/Modals/LetterInLeui/EmployerLetterModal.vue';
import { Application } from '@/models/Entities/Application/Application';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { User } from '@/models/Entities/User';
import SnackbarMixin from '@/mixins/snackbarMixin';
import SlideOutComponent from '@/components/SlideOutComponent.vue';
import { Board } from '@/models/Entities/Board';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import YesNoModalComponent from '@/components/Modals/YesNoModalComponent.vue';

@Component<ApplicantPage>({
  components: {
    ApplicantSideNavComponent: ApplicantSideNavComponentV2,
    ApplicantLogsComponent,
    TmoLetterModal,
    HousingLetterModal,
    EmployerLetterModal,
    SlideOutComponent,
    YesNoModalComponent
  }
})
export default class ApplicantPage extends Mixins(Vue, SnackbarMixin) {
  @Prop()
  applicant!: Applicant;

  @Prop()
  currentUser!: User;

  // TODO: Do we still need these props?
  @Prop({ default: () => [] })
  applications!: Application[];

  @Prop({ default: () => [] })
  boards!: Board[];

  @Prop({ default: () => [] })
  inProcessingClasses!: InProcessingClass[];

  @Prop({ default: () => [] })
  otsClasses!: OfficerTrainingSchoolClass[];

  showNotes = false;
  showOutProcessing = false;

  immediateAction = false;

  logs = [];

  $refs!: {
    tmoLetterModal: TmoLetterModal;
    housingLetterModal: HousingLetterModal;
    employerLetterModal: EmployerLetterModal;
    yesNoModal: YesNoModalComponent;
  };

  @Watch('$route.query.showNotes', { immediate: true })
  watchNoteQuery(showNotes: string): void {
    this.showNotes = showNotes === 'true';
  }

  @Watch('$route.query.immediateAction', { immediate: true })
  watchImmediateActionQuery(immediateAction: string): void {
    this.immediateAction = immediateAction === 'true';
  }

  @Watch('$route.params', { immediate: true })
  async fetchData(): Promise<void> {
    this.$store.dispatch('profileSideNavigationModule/clear');
  }

  @ProvideReactive('isUpdating')
  isUpdating = false;

  fetchApplicant(): Promise<void> {
    return this.$store.dispatch('applicantModule/fetch', this.$route.params.id);
  }

  get applicantLogs(): Log[] {
    return [...this.applicant.logs].sort(
      (a: Log, b: Log) =>
        b.createDateTime.valueOf() - a.createDateTime.valueOf()
    );
  }

  closeNoteSlideout(): void {
    this.showNotes = false;
    this.$router.push(`/applicant/${this.applicant.id}/notes-and-orders`);
  }

  async openModal(modalType: ELetterInLeuiTypes): Promise<void> {
    try {
      switch (modalType) {
        case ELetterInLeuiTypes.TMO:
          await this.$refs.tmoLetterModal.open();
          break;
        case ELetterInLeuiTypes.HOUSING:
          await this.$refs.housingLetterModal.open();
          break;
        case ELetterInLeuiTypes.EMPLOYER:
          await this.$refs.employerLetterModal.open();
          break;
      }
    } catch (e) {
      if (e) this.showLetterInLeiuError(modalType);
    }
  }

  async createLog(log: Log): Promise<void> {
    try {
      await this.$store.dispatch('applicantModule/createApplicantLog', {
        id: this.applicant.id,
        log: {
          ...log,
          candidateId: this.applicant.id
        }
      });
    } catch (err: any) {
      if (err) {
        this.showSnackbar({
          message: err.message ?? 'Note was not created, please try again ',
          icon: 'caution'
        });
      }
    }
  }

  async updateLog(log: Log): Promise<void> {
    await this.$store.dispatch('applicantModule/patchApplicantLog', {
      logId: log.id,
      payload: log
    });
  }

  async archiveLog(log: Log): Promise<void> {
    try {
      const result = await this.$refs.yesNoModal.open({
        title: `Delete`,
        message: `Are you sure you want to delete?`
      });
      if (!result) return;
      await this.$store.dispatch('candidateModule/archiveLog', {
        id: this.applicant.id,
        logId: log.id
      });
      await this.$store.dispatch(
        'applicantModule/fetchWithOtsAndInProClasses',
        this.applicant.id
      );
    } catch (err: any) {
      if (err) {
        this.showSnackbar({
          message: err.message ?? 'Note was not archived, please try again ',
          icon: 'caution'
        });
      }
    }
  }

  async updateApplicant(applicant: Applicant): Promise<void> {
    try {
      this.isUpdating = true;
      await this.$store.dispatch('candidateModule/update', {
        id: applicant.id,
        payload: applicant
      });
      // Fetch applicant
      await this.fetchApplicant();
      this.showSnackbar({
        message: 'Updates Saved',
        icon: 'checkmark'
      });
    } catch (err: any) {
      this.showSnackbar({
        message:
          err.message ??
          'there was an error updating this candidate please try again.',
        icon: 'x'
      });
    } finally {
      this.isUpdating = false;
    }
  }

  showLetterInLeiuError(letterInLeiuType: string): void {
    this.showSnackbar({
      message: `error occurred with generating ${letterInLeiuType} letter`,
      icon: { name: 'circleFilledMinus', fill: 'red', stroke: 'black' }
    });
  }
}
