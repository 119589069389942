var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { on: { close: _vm.close } }, [
        _c("div", { staticClass: "p-4 px-8 text-xs" }, [
          _c("h2", { staticClass: "mb-4 text-xl" }, [
            _vm._v("Letters in Leiu of Orders Housing"),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("date-selector", {
                  attrs: {
                    "data-cy": "DateArriving",
                    label: "Date Arriving",
                    initialDate: _vm.initialDate,
                    required: true,
                    placeholder: "Select a date",
                    errorText: "Please select an arrival date",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("dateOfArrival", $event)
                    },
                  },
                  model: {
                    value: _vm.housingData.dateArriving,
                    callback: function ($$v) {
                      _vm.$set(_vm.housingData, "dateArriving", $$v)
                    },
                    expression: "housingData.dateArriving",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "TravelFromLocation",
                    errorText: "Enter location being traveled from",
                    required: true,
                    placeholder: "Enter Location",
                    label: "Travel From Location",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("TravelFromLocation", $event)
                    },
                  },
                  model: {
                    value: _vm.housingData.fromLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.housingData, "fromLocation", $$v)
                    },
                    expression: "housingData.fromLocation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "TravelToLocation",
                    errorText: "Enter location being traveled to",
                    required: true,
                    placeholder: "Enter Location",
                    label: "Travel To Location",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("TravelToLocation", $event)
                    },
                  },
                  model: {
                    value: _vm.housingData.toLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.housingData, "toLocation", $$v)
                    },
                    expression: "housingData.toLocation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "flex items-center justify-end" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                  attrs: { "data-cy": "TmoModalCancelButton", type: "button" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 text-white rounded focus:outline-none focus:shadow-outline",
                  class: {
                    "opacity-50": !_vm.valid,
                    "bg-gray-500": !_vm.valid,
                    "bg-blue-500": _vm.valid,
                  },
                  attrs: {
                    "data-cy": "TmoModalSubmitButton",
                    type: "button",
                    disabled: !_vm.valid,
                  },
                  on: { click: _vm.generateLetterHandler },
                },
                [_vm._v(" Generate Letter ")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }